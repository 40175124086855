export const initPriceRange = () => {
    // VARS
    const priceInputs = document.querySelectorAll('[data-price-value]');
    if (!priceInputs.length) return;

    // LISTENERS
    priceInputs.forEach((input) => {
        input.addEventListener('change', handleOnchangePrice, false);
    });

    // HANDLERS
    function handleOnchangePrice() {
        const card = this.closest('[data-price-table-card]');
        if (!card) return;
        const priceElem = card.querySelector('[data-price]');
        if (!priceElem) return;
        priceElem.textContent = this.value;
    }
};
