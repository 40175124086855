export const initHamburger = () => {
    // VARS
    const burgerButton = document.querySelector('.burger__button');
    if (!burgerButton) return;
    const menuDropDown = document.querySelector('.header__toolbar');
    const submenuDropDown = document.querySelector('.main-menu__submenu');
    const submenuDropDownButton = document.querySelector('.main-menu__link--dropdown');

    // LISTENERS
    burgerButton.addEventListener('click', function () {
        burgerButton.classList.toggle('burger__button--active');

        if (burgerButton.classList.contains('burger__button--active')) {
            menuDropDown.classList.add('header__toolbar--opened');
        } else {
            menuDropDown.classList.remove('header__toolbar--opened');
        }
    });

    submenuDropDownButton.addEventListener('click', function () {
        submenuDropDown.classList.toggle('main-menu__submenu--opened');
    });
};
