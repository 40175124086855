import { decorateUrl } from '../gtm/decorateUrl';
import { generateGl } from '../gtm/generateGl';
import { objectToQueryString } from '../helper-functions/objectToQueryString';
import { generateGA } from '../gtm/generateGa';
import { getCookie } from '../helper-functions/getCookie';
import { Base64 } from 'js-base64';

export const initRedirectToCheckout = () => {
    // VARS
    const forms = document.querySelectorAll('[data-plan-form]');
    if (!forms.length) return;

    // EVENTS
    [...forms].forEach((form) => {
        form.addEventListener('submit', handleOnSubmitForm, false);
    });

    // HANDLERS
    function handleOnSubmitForm(event) {
        event.preventDefault();
        redirect(this);
    }
};

export function redirect(form) {
    let url = document.querySelector('[data-plan-form-url]').dataset.planFormUrl;
    const urlFront = document.querySelector('[data-plan-form-front-url]')?.dataset.planFormFrontUrl;
    const params = JSON.parse(document.querySelector('[data-plan-form-params]').dataset.planFormParams) ?? {};
    const hiddenInputs = form.querySelectorAll('input[type="hidden"]');
    const email = form.querySelector('[data-plan-form-email]');
    const radioInputs = form.querySelectorAll('input[type="radio"]:checked');

    params['_gl'] = generateGl() || null;

    [...hiddenInputs].forEach((hiddenInput) => {
        params[hiddenInput.name] = hiddenInput.value.trim();
    });

    if (urlFront?.length && params.item_code) {
        url = urlFront + params.item_code + '/' + params.theme;
    }

    if (email) {
        params.email = email.value.trim();
    }

    [...radioInputs].forEach((radioInput) => {
        params[radioInput.name] = radioInput.value.trim();
    });

    if (params.phoneNumber) {
        params.additional = {
            ...params.additional,
            geofinderPhoneNumber: params.phoneNumber,
        };
    }

    if (window.pageLoader) {
        window.pageLoader.show();
    }

    if (!params.ga_client_id) {
        params['ga_client_id'] = generateGA()['ga_client_id'];
    }

    if (!params.ga_session_id) {
        params['ga_session_id'] = generateGA()['ga_session_id'];
    }

    if (undefined !== getCookie('fingerprint')) {
        params['fingerprint'] = getCookie('fingerprint');
    }

    if (Boolean(getCookie('jivoChatHash')) && Boolean(getCookie('jivoChatVisitor'))) {
        const hash = getCookie('jivoChatHash');
        const hashClient = `jv_store_${hash}_client`;
        const hashApp = `jv_store_${hash}_app`;
        const jv_store_client = localStorage.getItem(hashClient);
        const jv_store_app = localStorage.getItem(hashApp);

        let jivoChatSession = {};
        if (Boolean(jv_store_client) && Boolean(jv_store_app)) {
            for (var i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i).substring(0, 3) === 'jv_') {
                    const jvKey = localStorage.key(i);
                    let jvContent = JSON.parse(localStorage.getItem(localStorage.key(i)));
                    if (Boolean(jvContent['text'])) {
                        jvContent['text'] = jvContent['text'].replaceAll(
                            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]|\n)/g,
                            ' ',
                        );
                    }

                    if (localStorage.key(i).substring(0, 11) !== 'jv_eBuffer_') {
                        jivoChatSession[jvKey] = jvContent;
                    }
                }
            }

            params['jivo'] = { visitor: getCookie('jivoChatVisitor') };

            const jvSyncUrl = document.querySelector('[data-checkout-jivo-session-url]');
            if (!jvSyncUrl) {
                window.location.href = decorateUrl(url + '?' + objectToQueryString(params));
            }

            fetch(jvSyncUrl.dataset.checkoutJivoSessionUrl, {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    visitor: getCookie('jivoChatVisitor'),
                    hash: getCookie('jivoChatHash'),
                    session: Base64.encode(JSON.stringify(jivoChatSession)),
                }),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    window.location.href = decorateUrl(url + '?' + objectToQueryString(params));
                })
                .catch(() => {
                    window.location.href = decorateUrl(url + '?' + objectToQueryString(params));
                });
        }
    }

    window.location.href = decorateUrl(url + '?' + objectToQueryString(params));
}
