import { initPricesTable } from './components/priceTable/initPricesTable';
import { initHamburger } from './components/menu/initHamburger';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initAppendHiddenInputsToPlanForms } from '../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';
import { initRedirectToCheckout } from '../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';

document.addEventListener('DOMContentLoaded', function () {
    initPricesTable();
    initHamburger();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initAppendHiddenInputsToPlanForms();
    initRedirectToCheckout();
});
